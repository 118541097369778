/* eslint-disable no-unsafe-optional-chaining */
import withFormProvider from '@commons/HOCs/FormProviderHOC'
import Loader from '@commons/Loader'
import AppModal from '@commons/Modal/AppModal'
import AppTabs from '@commons/Tabs/AppTabs'
import TabPanel from '@commons/Tabs/TabPanel'
import ConvertToPercentage from '@commons/convertToPercentage'
import { complianceFrameworkTypes } from '@constants/Compliance/complianceConstants'
import { customEvents } from '@constants/analytics'
import { ComplianceControlStatus, complianceDocumentFormConstant } from '@constants/formConstants/complianceFormConstants'
import { permissionKeys } from '@constants/permissions'
import { fetchAllNotificationsReducer } from '@redux/notifications/reducers'
import {
	deleteDocs,
	getAllDocs,
	getControlsByFrameworkId,
	getFrameworksList,
	updateComplianceDocs,
	uploadComplianceDocs
} from '@services/complianceService'
import { getUsersOrganisation } from '@services/userService'
import { jsonToFormData } from '@utils/form'
import { formatDateTime, logGAEvent, replaceByRegex } from '@utils/other'
import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { createSearchParams, useNavigate } from 'react-router-dom'
import CompliancePendingPolicies from './Documents/CompliancePendingPolicies'
import ComplianceUploadPolicyForm from './Documents/ComplianceUploadPolicyForm'
import ComplianceUploadedPolicies from './Documents/ComplianceUploadedPolicies'
import { usersReducer } from '@redux/users/slice'

const ComplianceDocuments = ({ complianceType, activeSubTabIndex, activeTabIndex }) => {
	// eslint-disable-next-line no-unused-vars
	const { control, reset, setError, watch } = useFormContext()
	const { enqueueSnackbar } = useSnackbar()
	const [uploadPercentage, setUploadPercentage] = React.useState(0)
	const [uploadDocProgress, setUploadDocProgress] = useState(0)
	const [totalUploadControls, setTotalUploadControls] = useState(0)
	const [uploadDocCount, setUploadDocCount] = useState(0)
	const [modalIsOpen, setModalIsOpen] = React.useState(false)
	const [complianceFrameworks, setComplianceFrameworks] = React.useState({})
	const [isEditMode, setIsEditMode] = React.useState(false)
	const [isLoading, setIsLoading] = React.useState(false)
	const [pendingPolicyList, setPendingPolicyList] = useState([])
	const [uploadedPolicyList, setUploadedPolicyList] = useState([])
	const [rowDocumentDetails, setRowDocumentDetails] = useState()
	const defaultAssignee = useSelector((state) => state.auth.user.info.email)
	const tabChangeTrigger = useSelector((state) => state.users.activeTabIndex)
	const dispatch = useDispatch()
	const policyByRouteName = useSelector((state) => state.auth.policyByRouteName)
	const statusWatcher = useWatch({
		control,
		defaultValue: undefined,
		name: 'status'
	})
	const { current: tabs } = React.useRef([
		{
			label: 'Published policies'
		},
		{
			label: 'Pending policies'
		}
	])

	const navigate = useNavigate()

	const onTabChange = (e, index) => {
		if (activeSubTabIndex !== index) {
			navigate({
				pathname: window.location.pathname,
				search: createSearchParams({
					subTab: index,
					tab: activeTabIndex
				}).toString()
			})
		}
	}

	const [organisation, setOrganisation] = React.useState({
		members: [],
		memberById: {}
	})

	const complianceStatus = [
		{
			label: 'Published',
			value: 'Published'
		}
	]

	const formConst = React.useMemo(() => {
		return complianceDocumentFormConstant(organisation.memberOptions, complianceStatus)
	}, [JSON.stringify(organisation)])

	const frameWorkId = React.useMemo(() => {
		return complianceFrameworks[complianceType]?.id
	}, [complianceFrameworks[complianceType]?.id])

	const handleModalOpen = (e) => {
		{
			setModalIsOpen(true)
			e.stopPropagation()
		}

		e.stopPropagation()
	}

	const handleModalClose = () => {
		if (!modalIsOpen) {
			mixpanel.time_event(customEvents.TOGGLED_MODAL)
		} else {
			mixpanel.track(customEvents.TOGGLED_MODAL, {
				url: window.location.pathname,
				title: `Compliance ${isEditMode ? 'Edit' : 'Upload new'} document`
			})
		}
		setUploadPercentage(0)
		setModalIsOpen(false)
		reset({})
		setIsEditMode(false)
		setUploadDocProgress(0)
		setUploadDocCount(0)
		setTotalUploadControls(0)
	}

	React.useEffect(() => {
		if (totalUploadControls > 0 && uploadDocCount > 0) {
			const uploadInPercent = ConvertToPercentage(uploadDocCount, totalUploadControls)
			setUploadDocProgress(parseInt(uploadInPercent))
		}
	}, [uploadDocCount, totalUploadControls])

	const onUploadProgress = (progressEvent) => {
		const { loaded, total } = progressEvent
		const totalUploadPercentage = parseInt((loaded / total) * 100)
		if (totalUploadPercentage === 100) {
			setUploadPercentage(0)
		} else {
			setUploadPercentage(totalUploadPercentage)
		}
	}

	const setNoDocumentError = () => {
		setError('document', {
			type: 'manual',
			message: 'Document is required!'
		})
	}

	const isValidFormat = (documentName) => {
		const extension = documentName.split('.').pop().toLowerCase()
		return extension === 'pdf' || extension === 'docx' || extension === 'xlsx' || extension === 'csv'
	}

	const handleMultipleControlUploadDocs = async (document, controlId, date, assigned_to, statusDocs, controlDocId) => {
		const dateString = typeof date === 'string' ? date : date?.toISOString() || new Date().toISOString()
		const reqBody = {
			date: dateString,
			status: statusDocs || '',
			assigned_to: assigned_to?.value || '',
			// control: isEditMode ? control[0]?.value : control[0]?.value,
			control: controlId,
			framework_id: frameWorkId,
			type: complianceFrameworkTypes.Compliance
		}
		if (document?.length > 0) {
			if (!!document && typeof document !== 'string') {
				// create mode
				if (!isEditMode) {
					const request = uploadComplianceDocs()
					const formData = jsonToFormData(reqBody)

					document.map((each) => formData.append('documents', each))

					console.log('reqBody', reqBody)
					const response = await request.api({
						data: formData
					})

					if (response) {
						if (!response.error) {
							logGAEvent(customEvents.FORM_SUBMIT_SUCCESS, {
								event_label: `upload new document`
							})
							setUploadDocCount((prev) => prev + 1)
							enqueueSnackbar('Successfully updated!', {
								variant: 'success'
							})
						}
					} else {
						logGAEvent(customEvents.FORM_SUBMIT_FAILED, {
							event_label: `upload new document`
						})
						enqueueSnackbar('Some documents have not been updated successfully!', {
							variant: 'error'
						})
					}
				}
				// Edit mode
				else {
					const request = updateComplianceDocs(controlDocId, { onUploadProgress })
					const formData = jsonToFormData(reqBody)

					document.map((each) => formData.append('documents', each))

					console.log('reqBody', reqBody)

					const response = await request.api({
						data: formData
					})

					if (response) {
						if (!response.error) {
							logGAEvent(customEvents.FORM_SUBMIT_SUCCESS, {
								event_label: `upload new document`
							})
							setUploadDocCount((prev) => prev + 1)
							enqueueSnackbar('Successfully updated!', {
								variant: 'success'
							})
						}
					} else {
						logGAEvent(customEvents.FORM_SUBMIT_FAILED, {
							event_label: `upload new document`
						})
						enqueueSnackbar('Some documents have not been updated successfully!', {
							variant: 'error'
						})
					}
				}
			}
		} else {
			const request = isEditMode ? updateComplianceDocs(controlDocId, { onUploadProgress }) : uploadComplianceDocs()

			const response = await request.api({
				data: jsonToFormData(reqBody)
			})

			if (response) {
				if (!response.error) {
					logGAEvent(customEvents.FORM_SUBMIT_SUCCESS, {
						event_label: `upload new document`
					})
					setUploadDocCount((prev) => prev + 1)
					enqueueSnackbar('Successfully updated!', {
						variant: 'success'
					})
				}
			} else {
				logGAEvent(customEvents.FORM_SUBMIT_FAILED, {
					event_label: `upload new document`
				})
				enqueueSnackbar('Some documents have not been updated successfully!', {
					variant: 'error'
				})
			}
		}
	}
	// eslint-disable-next-line no-unused-vars
	const handleFormSubmit = async ({ document, control = [], date, assigned_to, status, ...data }) => {
		status = status ? status.value : undefined
		if (!(assigned_to && assigned_to.value)) {
			return setError('assigned_to', {
				type: 'manual',
				message: 'Assignee is required!'
			})
		}

		if (document) {
			if (document?.length > 0) {
				for (let i = 0; i < document.length; i++) {
					const attachedFile = document[i]

					if (attachedFile?.size > 10 * 1024 * 1024) {
						setError('document', {
							type: 'manual',
							message: 'Document size must not exceed 10MB !'
						})
						return
					}

					if (attachedFile) {
						if (typeof attachedFile !== 'string') {
							if (!isValidFormat(attachedFile.name)) {
								return enqueueSnackbar(
									` Please check the file type. Only .pdf, .xlsx, .csv and .docx files are accepted.`,
									{
										variant: 'error',
										// persist: true,
										preventDuplicate: false,
										autoHideDuration: 5000
									}
								)
							}
						}
					}
				}
			}
			setUploadDocProgress(3)
		}

		if (statusWatcher) {
			console.log({ rowDocumentDetails, document, statusWatcher, isEditMode })
			if (
				statusWatcher.value === ComplianceControlStatus.Published &&
				(isEditMode
					? (!rowDocumentDetails && !document) ||
					(Array.isArray(rowDocumentDetails) && rowDocumentDetails.length === 0 && !document)
					: !document)
			) {
				setNoDocumentError()
				logGAEvent(customEvents.FORM_SUBMIT_FAILED, {
					event_label: `upload new document`
				})
				return
			}
		}
		if (!(control && control[0].value)) {
			setError('control', {
				type: 'manual',
				message: 'Control Id is required!'
			})
			return
		}

		if (!isEditMode) {
			const totalControls = control.length
			setTotalUploadControls(totalControls)
			for (let i = 0; i < totalControls; i++) {
				await handleMultipleControlUploadDocs(document, control[i].value, date, assigned_to, status)
			}
			handleModalClose()

			reset({
				documents: [],
				document: null,
				date: null,
				status: undefined,
				assigned_to: '',
				control: []
			})
			fetchData()
		} else {
			const totalControls = control.length
			setTotalUploadControls(totalControls)

			for (let i = 0; i < totalControls; i++) {
				await handleMultipleControlUploadDocs(document, control[i].value, date, assigned_to, status, control[i].id)
			}

			handleModalClose()
			reset({
				documents: [],
				document: null,
				date: null,
				status: undefined,
				assigned_to: '',
				control: []
			})
			fetchData()
		}
		dispatch(fetchAllNotificationsReducer())
	}

	const fetchData = () => {
		const request1 = getAllDocs()
		const request2 = getControlsByFrameworkId()

			; (async () => {
				setIsLoading(true)
				//uploaded policies
				const response1 = await request1.api({
					params: {
						framework_id: frameWorkId,
						type: complianceFrameworkTypes.Compliance
					}
				})

				//pending policies
				const response2 = await request2.api({
					params: {
						framework_id: frameWorkId,
						exclude_existing: 1
					}
				})

				if (response1) {
					if (!response1.error && response1?.results?.data?.length > 0) {
						const responseData = response1.results.data
						let finalData = []
						responseData.map((control) => {
							finalData = [
								...finalData,
								{
									...control,

									label: `${control.control_details.ref_code} - ${control.control_details.description?.substring(0, 50)} ...`,
									value: control.control_details.id
								}
							]
						})
						setUploadedPolicyList(finalData)
					}
				}

				if (response2 && !response2.error && Array.isArray(response2?.results?.data)) {
					const responseData = response2.results.data

					let finalData = []
					responseData.map((each) => {
						each?.subcontrols?.map((control) => {
							finalData = [
								...finalData,
								{
									...control,
									control_details: {
										ref_code: control.ref_code,
										id: control.id,
										description: control.description,
										category: control.category
									},

									label: `${control.ref_code} - ${control.description?.substring(0, 50)} ...`,
									value: control.id
								}
							]
						})
					})
					setPendingPolicyList(finalData)
				}

				setIsLoading(false)
			})()

		return {
			request1,
			request2
		}
	}

	const fetchEvidenceAssigneeList = () => {
		const getEvidenceAssigneeListApi = getUsersOrganisation()
		getEvidenceAssigneeListApi.api().then((response) => {
			if (!response.error && response.results && response.results.data && Array.isArray(response.results.data.members)) {
				const tempMemberList = response.results.data.members
					.map((each) => ({
						label: each.last_name
							? `${each.first_name} ${each.last_name} - ${each.email}`
							: `${each.first_name} - ${each.email}`,
						username: each.username,
						value: each.id
					}))
					.filter(member => !member.label.includes("undefined"));

				if (tempMemberList?.length > 0) {
					dispatch(usersReducer(tempMemberList))
				}
			}
		})

		return getEvidenceAssigneeListApi
	}

	const fetchOrganisation = () => {
		const organisationApi = getUsersOrganisation()

		organisationApi.api().then((response) => {
			if (response) {
				if (!response.error) {
					setOrganisation({
						...response?.results?.data,
						// TODO: Implement REDUX for THIS API
						memberById: response?.results?.data?.members.reduce((obj, item) => {
							obj[item.id] = item
							return obj
						}, {}),
						memberOptions: response?.results?.data?.members
							.map((each) =>
								each.id
									? {
										label: each.email,
										value: each.id
									}
									: undefined
							)
							.filter((each) => !!each)
					})
				}
			}
		})

		return organisationApi
	}

	function extractTextAfterHyphen(inputString) {
		const parts = inputString.split('-')

		if (parts.length > 1) {
			return parts[1].trim()
		} else {
			return inputString // Return null or any other default value you prefer
		}
	}

	const handleEditUploadDocument = (row, editMode) => (e) => {
		if (policyByRouteName[permissionKeys.ManageStandardsAndMandates]) {
			handleModalOpen(e)
			console.log({ row, editMode })
			setRowDocumentDetails(row?.compliance?.documents)
			setIsEditMode(!!editMode)
			if (editMode) {
				reset({
					status: undefined, // complianceStatus.find((value) => value.value == row?.compliance?.status),
					assigned_to: organisation?.memberOptions?.find((member) => member?.value === row?.compliance?.assigned_to),
					control: [uploadedPolicyList.find((compliance) => compliance?.control_details?.ref_code === row?.ref_code)],
					date: row?.compliance?.date ? moment(formatDateTime(row?.compliance?.date)) : moment(),
					document: row?.compliance?.document,
					documents: []
				})
			} else {
				reset({
					status: undefined,
					assigned_to: organisation.memberOptions.find((member) => extractTextAfterHyphen(member.label) === defaultAssignee),
					control: [pendingPolicyList?.find((compliance) => compliance?.value === row?.control_details?.id)],
					date: moment(row?.date),
					document: '',
					documents: []
				})
			}
		} else {
			enqueueSnackbar('You do not have permission to perform this action. Contact Admin', { variant: 'error' })
			e.stopPropagation()
		}
	}

	const fetchFramework = () => {
		const req = getFrameworksList()
		setIsLoading(true)
		req.api({}).then((response) => {
			if (response) {
				setIsLoading(false)
				if (!response.error && Array.isArray(response.results.data) && response.results.data?.length > 0) {
					setComplianceFrameworks(
						response.results.data.reduce((obj, item) => {
							obj[replaceByRegex(item.key.toLowerCase(), '-', / /g)] = item

							return obj
						}, {})
					)
				}
			}
		})

		return req
	}

	const deletePoliciesDocument = (link, data) => (e) => {
		e.stopPropagation()
		// const startString = 'devzeron/'
		// const endString = '?'
		// const startIndex = link.indexOf(startString) + startString.length
		// const endIndex = link.indexOf(endString)
		const request = updateComplianceDocs(data?.id)
		const fileName = decodeURIComponent(`compliance_documents/${decodeURI(
			link.replace('devzeron/compliance_documents/', '').split('?')[0].split('/').pop()
		)}`)
		request
			.api({
				data: {
					control: data?.control,
					assigned_to: data?.assigned_to,
					date: data?.date,
					delete_documents: [fileName],
					type: complianceFrameworkTypes.Compliance,
					framework_id: data?.framework_id
				}
			})
			.then(() => {
				fetchData()
				enqueueSnackbar('Document deleted successfully', {
					variant: 'success'
				})
			})

		return request
	}

	const deleteDocument = (id) => (e) => {
		if (policyByRouteName[permissionKeys.ManageStandardsAndMandates]) {
			const request = deleteDocs(id)
			e.stopPropagation()
			request
				.api({
					params: {
						type: complianceFrameworkTypes.Compliance,
						framework_id: frameWorkId
					}
				})
				.then(() => {
					fetchData()
					enqueueSnackbar('Document deleted successfully', {
						variant: 'success'
					})
					dispatch(fetchAllNotificationsReducer())
				})

			return request
		} else {
			enqueueSnackbar('You do not have permission to perform this action. Contact Admin', { variant: 'error' })
			e.stopPropagation()
		}

	}

	React.useEffect(() => {
		if (frameWorkId) {
			// eslint-disable-next-line no-unused-vars
			const { request1, request2 } = fetchData()
			const request = fetchOrganisation()

			return () => {
				request1.cancel()
				request2.cancel()
				request.cancel()
			}
		}
	}, [frameWorkId , tabChangeTrigger])

	React.useEffect(() => {
		const request = fetchFramework()
		const usersData = fetchEvidenceAssigneeList()

		return () => {
			request.cancel()
			usersData.cancel()
		}
	}, [])

	React.useEffect(() => {
		const request = fetchFramework()
		const usersData = fetchEvidenceAssigneeList()

		return () => {
			request.cancel()
			usersData.cancel()
		}
	}, [tabChangeTrigger])


	// React.useEffect(() => {
	// 	if (!isNaN(Number(subTab))) {
	// 		setActiveTabIndex(Number(subTab))
	// 	}
	// }, [subTab])

	return (
		<>
			<AppTabs tabs={tabs} onTabChange={onTabChange} activeTabIndex={activeSubTabIndex} />

			<TabPanel index={activeSubTabIndex} value={0}>
				<ComplianceUploadedPolicies
					membersById={organisation.memberById}
					deleteDocument={deleteDocument}
					handleEditUploadDocument={handleEditUploadDocument}
					isLoading={isLoading}
					deletePoliciesDocument={deletePoliciesDocument}
					frameworkId={frameWorkId}
					uploadedPolicyList={uploadedPolicyList}
				/>
			</TabPanel>
			<TabPanel index={activeSubTabIndex} value={1}>
				<CompliancePendingPolicies
					membersById={organisation.memberById}
					handleEditUploadDocument={handleEditUploadDocument}
					isLoading={isLoading}
					frameworkId={frameWorkId}
					deletePoliciesDocument={deletePoliciesDocument}
					pendingPolicyList={pendingPolicyList}
				/>
			</TabPanel>
			<AppModal
				fullWidth
				maxWidth='sm'
				title={`${isEditMode ? 'Edit' : 'Upload new'} document`}
				hideClose
				open={modalIsOpen}
				onClose={handleModalClose}
			>
				{isEditMode ? (
					uploadedPolicyList.length === 0 ? (
						<Loader showSecurityFacts={false} />
					) : (
						<ComplianceUploadPolicyForm
							handleFormSubmit={handleFormSubmit}
							formConst={formConst}
							documentLink={rowDocumentDetails}
							handleModalClose={handleModalClose}
							uploadPercentage={uploadPercentage}
							complianceControlsList={isEditMode ? uploadedPolicyList : pendingPolicyList}
							isEditMode={isEditMode}
							uploadDocProgress={uploadDocProgress}
						/>
					)
				) : pendingPolicyList.length === 0 ? (
					<Loader showSecurityFacts={false} />
				) : (
					<ComplianceUploadPolicyForm
						handleFormSubmit={handleFormSubmit}
						formConst={formConst}
						handleModalClose={handleModalClose}
						// documentLink={rowDocumentDetails}
						uploadDocProgress={uploadDocProgress}
						uploadPercentage={uploadPercentage}
						complianceControlsList={isEditMode ? uploadedPolicyList : pendingPolicyList}
						isEditMode={isEditMode}
					/>
				)}
			</AppModal>
		</>
	)
}

export default withFormProvider(ComplianceDocuments)
