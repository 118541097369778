import AppChip from '@commons/AppChip'
import Button from '@commons/Button/Button'
import CardWrapperByRatio from '@commons/Card/CardWrapperByRatio'
import Collapsible from '@commons/Collapsible/Collapsible'
import Loader from '@commons/Loader'
import AppModal from '@commons/Modal/AppModal'
import NothingIsSelected from '@commons/NothingIsSelected'
import CircularProgressWithLabel from '@commons/ProgressBar/CircularProgressWithLabel'
import RecommendedTools from '@commons/RecomendedTools'
import OpenSearchDataTable from '@commons/Table/OpenSearchDataTable'
import Text from '@commons/Text'
import { complianceSlugs } from '@constants/adminRoutesConstant'
import { complianceFrameworkTypes } from '@constants/Compliance/complianceConstants'
import { allSignalsHeadCells } from '@constants/headcells/hostsHeadCells'
import { permissionKeys } from '@constants/permissions'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { Box, Grid, IconButton, Switch, useTheme } from '@mui/material'
import { controlVisited } from '@redux/users/slice'
import { applicableTrigger, applicableTriggerConditional, getControlsByFrameworkId, getFrameworksList } from '@services/complianceService'
import { getElasticData } from '@services/graphService'
import { ASCENTS, BREAKPOINTS } from '@styles/theme'
import { jsonToFormData } from '@utils/form'
import { calculateFixedInterval, getAggregationFromElasticResponse, replaceByRegex } from '@utils/other'
import { enqueueSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ComplianceControlsTable from './ComplianceControlsTable'

const ComplianceControls = ({ complianceType = '', subcontrol_ref_code }) => {
	const [isLoading, setIsLoading] = useState(false)
	// const currentLocation = useLocation()
	const policyByRouteName = useSelector((state) => state.auth.policyByRouteName)
	const userList = useSelector((state) => state.users.users)
	const lastVisitedControl = useSelector((state) => state.users.lastVisitedControl)
	const tabChangeTrigger = useSelector((state) => state.users.activeTabIndex)
	const [complianceFrameworks, setComplianceFrameworks] = React.useState({})
	const [complianceControlsList, setComplianceControlsList] = React.useState({})
	const [openEvidenceListModal, setOpenEvidenceListModal] = React.useState(false)
	const [applicableChecked, setApplicableChecked] = React.useState(false)

	const timeRange = useSelector((state) => state.timeFrame.timeRange)

	const reloadToken = useSelector((state) => state.reloadToken)

	const theme = useTheme()
	const dispatch = useDispatch()

	const frameWorkId = React.useMemo(() => {
		return complianceFrameworks[complianceType]?.id
	}, [complianceFrameworks[complianceType]?.id])

	const cards = [
		{
			title: 'EDR',
			percent: 20,
			index: 1
		},
		{
			title: 'Active directory',
			percent: 50,
			index: 2
		},
		{
			title: 'Sentinel One',
			percent: 70,
			index: 3
		},
		{
			title: 'Qualys',
			percent: 90,
			index: 4
		},
		{
			title: 'Promisec',
			percent: 100,
			index: 4
		}
	]

	const [elasticRequestBodyByCompiance] = React.useState({
		[complianceSlugs.GdprScreen]: {
			getElasticRequestBody: (urlParams = {}) => ({
				aggs: {
					2: {
						terms: {
							field: 'rule.gdpr',
							order: {
								_count: 'desc'
							},
							size: 500
						}
					}
				},
				size: 0,
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				}
			})
		},

		[complianceSlugs.PciDssScreen]: {
			getElasticRequestBody: (urlParams = {}) => ({
				aggs: {
					2: {
						terms: {
							field: 'rule.pci_dss',
							order: {
								_count: 'desc'
							},
							size: 500
						}
					}
				},
				size: 0,
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				}
			})
		},

		[complianceSlugs.NistScreen]: {
			getElasticRequestBody: (urlParams = {}) => ({
				aggs: {
					2: {
						terms: {
							field: 'rule.nist_800_53',
							order: {
								_count: 'desc'
							},
							size: 500
						}
					}
				},
				size: 0,
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				}
			})
		},

		[complianceSlugs.Iso27001Screen]: {
			getElasticRequestBody: (urlParams = {}) => ({
				aggs: {
					2: {
						terms: {
							field: 'rule.iso_27001',
							order: {
								_count: 'desc'
							},
							size: 500
						}
					}
				},
				size: 0,
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				}
			})
		},

		[complianceSlugs.Iso27701Screen]: {
			getElasticRequestBody: (urlParams = {}) => ({
				aggs: {
					2: {
						terms: {
							field: 'rule.iso_27701',
							order: {
								_count: 'desc'
							},
							size: 500
						}
					}
				},
				size: 0,
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				}
			})
		},

		[complianceSlugs.Iso270012022Screen]: {
			getElasticRequestBody: (urlParams = {}) => ({
				aggs: {
					2: {
						terms: {
							field: 'rule.iso_27001_2022',
							order: {
								_count: 'desc'
							},
							size: 500
						}
					}
				},
				size: 0,
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				}
			})
		},

		[complianceSlugs.HippaScreen]: {
			getElasticRequestBody: (urlParams = {}) => ({
				aggs: {
					2: {
						terms: {
							field: 'rule.hipaa',
							order: {
								_count: 'desc'
							},
							size: 500
						}
					}
				},
				size: 0,
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				}
			})
		},

		[complianceSlugs.Soc2Screen]: {
			getElasticRequestBody: (urlParams = {}) => ({
				aggs: {
					2: {
						terms: {
							field: 'rule.tsc',
							order: {
								_count: 'desc'
							},
							size: 500
						}
					}
				},
				size: 0,
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				}
			})
		}
	})

	const elasticTableRequestBodyByRequirement = {
		[complianceSlugs.GdprScreen]: {
			getElasticRequestForRequirement: (key, urlParams = {}) => ({
				version: true,
				size: 500,
				sort: [
					{
						timestamp: {
							order: 'desc',
							unmapped_type: 'boolean'
						}
					}
				],
				aggs: {
					2: {
						date_histogram: {
							field: 'timestamp',

							...calculateFixedInterval(
								urlParams?.startDate
									? urlParams.startDate
									: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
								urlParams?.endDate ? urlParams.endDate : new Date().toISOString()
							),
							time_zone: 'Asia/Calcutta',
							min_doc_count: 1
						}
					}
				},
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								match_phrase: {
									'rule.gdpr': key
								}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				},
				highlight: {
					pre_tags: ['@kibana-highlighted-field@'],
					post_tags: ['@/kibana-highlighted-field@'],
					fields: {
						'*': {}
					},
					fragment_size: 2147483647
				}
			})
		},

		[complianceSlugs.PciDssScreen]: {
			getElasticRequestForRequirement: (key, urlParams = {}) => ({
				version: true,
				size: 500,
				sort: [
					{
						timestamp: {
							order: 'desc',
							unmapped_type: 'boolean'
						}
					}
				],
				aggs: {
					2: {
						date_histogram: {
							field: 'timestamp',

							...calculateFixedInterval(
								urlParams?.startDate
									? urlParams.startDate
									: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
								urlParams?.endDate ? urlParams.endDate : new Date().toISOString()
							),
							time_zone: 'Asia/Calcutta',
							min_doc_count: 1
						}
					}
				},
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								match_phrase: {
									'rule.pci_dss': key
								}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				},
				highlight: {
					pre_tags: ['@kibana-highlighted-field@'],
					post_tags: ['@/kibana-highlighted-field@'],
					fields: {
						'*': {}
					},
					fragment_size: 2147483647
				}
			})
		},

		[complianceSlugs.NistScreen]: {
			getElasticRequestForRequirement: (key, urlParams = {}) => ({
				version: true,
				size: 500,
				sort: [
					{
						timestamp: {
							order: 'desc',
							unmapped_type: 'boolean'
						}
					}
				],
				aggs: {
					2: {
						date_histogram: {
							field: 'timestamp',

							...calculateFixedInterval(
								urlParams?.startDate
									? urlParams.startDate
									: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
								urlParams?.endDate ? urlParams.endDate : new Date().toISOString()
							),
							time_zone: 'Asia/Calcutta',
							min_doc_count: 1
						}
					}
				},
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								match_phrase: {
									'rule.nist_800_53': key
								}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				},
				highlight: {
					pre_tags: ['@kibana-highlighted-field@'],
					post_tags: ['@/kibana-highlighted-field@'],
					fields: {
						'*': {}
					},
					fragment_size: 2147483647
				}
			})
		},
		[complianceSlugs.Iso27001Screen]: {
			getElasticRequestForRequirement: (key, urlParams = {}) => ({
				version: true,
				size: 500,
				sort: [
					{
						timestamp: {
							order: 'desc',
							unmapped_type: 'boolean'
						}
					}
				],
				aggs: {
					2: {
						date_histogram: {
							field: 'timestamp',

							...calculateFixedInterval(
								urlParams?.startDate
									? urlParams.startDate
									: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
								urlParams?.endDate ? urlParams.endDate : new Date().toISOString()
							),
							time_zone: 'Asia/Calcutta',
							min_doc_count: 1
						}
					}
				},
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								match_phrase: {
									'rule.iso_27001': key
								}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				},
				highlight: {
					pre_tags: ['@kibana-highlighted-field@'],
					post_tags: ['@/kibana-highlighted-field@'],
					fields: {
						'*': {}
					},
					fragment_size: 2147483647
				}
			})
		},
		[complianceSlugs.Iso270012022Screen]: {
			getElasticRequestForRequirement: (key, urlParams = {}) => ({
				version: true,
				size: 500,
				sort: [
					{
						timestamp: {
							order: 'desc',
							unmapped_type: 'boolean'
						}
					}
				],
				aggs: {
					2: {
						date_histogram: {
							field: 'timestamp',

							...calculateFixedInterval(
								urlParams?.startDate
									? urlParams.startDate
									: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
								urlParams?.endDate ? urlParams.endDate : new Date().toISOString()
							),
							time_zone: 'Asia/Calcutta',
							min_doc_count: 1
						}
					}
				},
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								match_phrase: {
									'rule.iso_27001': key
								}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				},
				highlight: {
					pre_tags: ['@kibana-highlighted-field@'],
					post_tags: ['@/kibana-highlighted-field@'],
					fields: {
						'*': {}
					},
					fragment_size: 2147483647
				}
			})
		},

		[complianceSlugs.HippaScreen]: {
			getElasticRequestForRequirement: (key, urlParams = {}) => ({
				version: true,
				size: 500,
				sort: [
					{
						timestamp: {
							order: 'desc',
							unmapped_type: 'boolean'
						}
					}
				],
				aggs: {
					2: {
						date_histogram: {
							field: 'timestamp',

							...calculateFixedInterval(
								urlParams?.startDate
									? urlParams.startDate
									: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
								urlParams?.endDate ? urlParams.endDate : new Date().toISOString()
							),
							time_zone: 'Asia/Calcutta',
							min_doc_count: 1
						}
					}
				},
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								match_phrase: {
									'rule.hipaa': key
								}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				},
				highlight: {
					pre_tags: ['@kibana-highlighted-field@'],
					post_tags: ['@/kibana-highlighted-field@'],
					fields: {
						'*': {}
					},
					fragment_size: 2147483647
				}
			})
		},

		[complianceSlugs.Soc2Screen]: {
			getElasticRequestForRequirement: (key, urlParams = {}) => ({
				version: true,
				size: 500,
				sort: [
					{
						timestamp: {
							order: 'desc',
							unmapped_type: 'boolean'
						}
					}
				],
				aggs: {
					2: {
						date_histogram: {
							field: 'timestamp',

							...calculateFixedInterval(
								urlParams?.startDate
									? urlParams.startDate
									: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
								urlParams?.endDate ? urlParams.endDate : new Date().toISOString()
							),
							time_zone: 'Asia/Calcutta',
							min_doc_count: 1
						}
					}
				},
				stored_fields: ['*'],
				script_fields: {},
				docvalue_fields: [
					{
						field: 'data.aws.createdAt',
						format: 'date_time'
					},
					{
						field: 'data.aws.end',
						format: 'date_time'
					},
					{
						field: 'data.aws.resource.instanceDetails.launchTime',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventFirstSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.service.eventLastSeen',
						format: 'date_time'
					},
					{
						field: 'data.aws.start',
						format: 'date_time'
					},
					{
						field: 'data.aws.updatedAt',
						format: 'date_time'
					},
					{
						field: 'data.timestamp',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.published',
						format: 'date_time'
					},
					{
						field: 'data.vulnerability.updated',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_after',
						format: 'date_time'
					},
					{
						field: 'syscheck.mtime_before',
						format: 'date_time'
					},
					{
						field: 'timestamp',
						format: 'date_time'
					}
				],
				_source: {
					excludes: ['@timestamp']
				},
				query: {
					bool: {
						must: [],
						filter: [
							{
								match_all: {}
							},
							{
								match_phrase: {
									'rule.tsc': key
								}
							},
							{
								range: {
									timestamp: {
										gte: urlParams?.startDate
											? urlParams.startDate
											: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
										lte: urlParams?.endDate ? urlParams.endDate : new Date().toISOString(),
										format: 'strict_date_optional_time'
									}
								}
							}
						],
						should: [],
						must_not: []
					}
				},
				highlight: {
					pre_tags: ['@kibana-highlighted-field@'],
					post_tags: ['@/kibana-highlighted-field@'],
					fields: {
						'*': {}
					},
					fragment_size: 2147483647
				}
			})
		}
	}

	const [requirementsAsPerCompliance, setRequirementsAsPerCompliance] = React.useState({})

	const [activeRequirement, setActiveRequirement] = React.useState({})


	React.useEffect(() => {
		if (Object.keys(lastVisitedControl)?.length > 0) {
			setRequirementsAsPerCompliance({})
			setActiveRequirement({})
			fetchControlLists()
			fetchRequirement()
			fetchFramework()
		}
	}, [lastVisitedControl])

	React.useEffect(() => {
		setRequirementsAsPerCompliance({})
		setActiveRequirement({})
		fetchControlLists()
		fetchRequirement()
		fetchFramework()
	}, [tabChangeTrigger])

	const handleOnActiveRequrirementChange = (key) => () => {
		key.applicable = key?.compliance?.status === 'Not Applicable' ? false : true;
		setApplicableChecked(false)
		setActiveRequirement(key)
	}

	const fetchRequirement = () => {
		const elasticRequestBody = elasticRequestBodyByCompiance[complianceType]
		const ESRequirement = getElasticData()
		setIsLoading(true)
		ESRequirement.api({
			data: {
				body:
					elasticRequestBody && elasticRequestBody.getElasticRequestBody
						? elasticRequestBody.getElasticRequestBody(timeRange)
						: elasticRequestBody,
				index: import.meta.env.VITE_APP_OS_DB_INDEX || 'zeron-alerts-*'
			}
		}).then((response) => {
			if (response) {
				setIsLoading(false)
				if (!response.error) {
					const { buckets } = getAggregationFromElasticResponse(response)
					if (buckets) {
						setRequirementsAsPerCompliance({})
						const { ...initalControlsByCompliance } = complianceControlsList

						const { ...controlsByPath } = initalControlsByCompliance

						buckets.map((each) => {
							// TODO: Improvise logix. HINT: Make the JSON constant to nested objects for each section in A.B.C.D
							const prefix =
								complianceType.toLowerCase() === 'hipaa'
									? each.key.split('.', 3).join('.')
									: complianceType.toLowerCase() === 'iso-27001' || complianceType.toLowerCase() === 'iso-27001-2022'
										? each.key.split('.', 2).join('.')
										: each.key.includes('_')
											? each.key.split('_')[0]
											: each.key.split('.')[0]

							if (prefix) {
								const eachControlPath = controlsByPath[prefix] && controlsByPath[prefix][each.key]

								if (eachControlPath) {
									eachControlPath.count = each.doc_count
								}
							}
						})

						setRequirementsAsPerCompliance(initalControlsByCompliance)
					}
				}
			}
		})

		return ESRequirement
	}

	const fetchFramework = () => {
		const req = getFrameworksList()

		req.api({}).then((response) => {
			if (response) {
				if (!response.error && response.results.data?.length > 0) {
					setComplianceFrameworks(
						response.results.data.reduce((obj, item) => {
							obj[replaceByRegex(item.key.toLowerCase(), '-', / /g)] = item

							return obj
						}, {})
					)
				}
			}
		})

		return req
	}

	const fetchControlLists = () => {
		setRequirementsAsPerCompliance({})
		const req = getControlsByFrameworkId()
		req.api({
			params: {
				framework_id: frameWorkId
			}
		}).then((response) => {
			if (response) {
				if (!response.error && response?.results?.data?.length > 0) {
					const state = response.results.data.reduce((obj, item) => {
						if (item.subcontrols) {
							if (!obj[item.ref_code]) {
								obj[item.ref_code] = item.subcontrols.reduce((subObj, subitem) => {
									subObj[subitem.ref_code] = {
										desc: subitem.description,
										...subitem,
										count: 0
									}

									return subObj
								}, {})
							}
						}

						return obj
					}, {})

					setComplianceControlsList(state)
					if (!policyByRouteName[permissionKeys.SecurityEventsNestedScreen] || !policyByRouteName[permissionKeys.RiskMitigationScreen] || !policyByRouteName[permissionKeys.IntegrationViewScreen] || !policyByRouteName[permissionKeys.HostNestedScreen] || !policyByRouteName[permissionKeys.DetectionNestedScreen] || !policyByRouteName[permissionKeys.IncidentResponseNestedScreen]) {
						setRequirementsAsPerCompliance(state)
					}
				}
			}
		})

		return req
	}

	const handleCardClick = (card) => {
		setOpenEvidenceListModal(!openEvidenceListModal)
	}

	const RenderCard = (card, index) => {
		return (
			<Grid item xs={3.8} component={Box}>
				<CardWrapperByRatio title={`${card.title}`}>
					{/* {loadingSummary || isLoading ? (
						<Box display='flex' justifyContent='center' alignItems='center' height='100%'>
							<Loader showSecurityFacts={false} />
						</Box>
					) : ( */}
					<>
						<CircularProgressWithLabel
							wrapperProps={{
								maxHeight: '100%',
								sx: {
									'& .CircularProgressbar': {
										width: '100%',
										height: '100%',
										display: 'block'
									}
								}
							}}
							strokeWidth={8}
							pathColorAscent='blue'
							textNode={
								<Text
									sx={{
										fontSize: 24,
										fontWeight: 600,
										textAlign: 'center',
										color: ASCENTS.amber,
										fontFamily: 'Open Sans',

										[theme.breakpoints.down(BREAKPOINTS.xxl)]: {
											fontSize: 22
										},

										'& .text': {
											textAlign: 'center',
											color: ASCENTS.amber,
											fontFamily: 'Open Sans',
											fontWeight: 600,

											[theme.breakpoints.down(BREAKPOINTS.xxl)]: {
												fontSize: 10
											}
										}
									}}
								>
									{card.percent}%<Text className={'text'}>Coverage</Text>
								</Text>
							}
							percentage={card.percent}
						/>
						<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: -3, marginBottom: 3, marginRight: -1 }}>
							<IconButton onClick={() => handleCardClick(card)}>
								<OpenInNewRoundedIcon fontSize='small' />
							</IconButton>
						</Box>
					</>
					{/* )} */}
				</CardWrapperByRatio>
			</Grid>
		)
	}

	const handleApplicableToggle = () => setApplicableChecked(!applicableChecked)

	React.useEffect(() => {
		if (policyByRouteName[permissionKeys.IntegrationHomeScreen]) {
			if (!!complianceType && Object.keys(complianceControlsList).length > 0) {
				const requestToken = fetchRequirement()
				// setRequirementByComplianceSlug();

				return () => {
					requestToken.cancel()
				}
			}
		}
	}, [JSON.stringify(complianceControlsList), complianceType, JSON.stringify(timeRange)])

	React.useEffect(() => {
		if (reloadToken) {
			const frameworkToken = fetchFramework()

			return () => {
				frameworkToken.cancel()
			}
		}
	}, [reloadToken])

	React.useEffect(() => {
		const frameworkToken = fetchFramework()

		return () => {
			frameworkToken.cancel()
		}
	}, [])

	React.useEffect(() => {
		if (frameWorkId) {
			const request = fetchControlLists()

			return () => {
				request.cancel()
			}
		}
	}, [frameWorkId])

	React.useEffect(() => {
		setActiveRequirement({})
	}, [complianceType])

	React.useEffect(() => {
		if (!!subcontrol_ref_code && Object.keys(requirementsAsPerCompliance).length > 0) {
			const subcontrolsByRefCode = Object.values(requirementsAsPerCompliance).find((subcontrol) => subcontrol[subcontrol_ref_code])
			if (subcontrolsByRefCode) {
				setActiveRequirement({
					...subcontrolsByRefCode[subcontrol_ref_code],
					key: subcontrol_ref_code,
					applicable: subcontrolsByRefCode[subcontrol_ref_code]?.compliance?.status === 'Not Applicable' ? false : true
				})
			}
		}
	}, [subcontrol_ref_code, JSON.stringify(requirementsAsPerCompliance)])


	React.useEffect(() => {
		if (!subcontrol_ref_code && Object.keys(requirementsAsPerCompliance).length > 0) {
			const subcontrolsByRefCode = Object.values(requirementsAsPerCompliance).find((subcontrol) => subcontrol[lastVisitedControl?.key])
			if (subcontrolsByRefCode) {
				setActiveRequirement({
					...subcontrolsByRefCode[lastVisitedControl?.key],
					key: lastVisitedControl?.key,
					applicable: subcontrolsByRefCode[lastVisitedControl?.key]?.compliance?.status === 'Not Applicable' ? false : true
				})
			}
		}
	}, [JSON.stringify(requirementsAsPerCompliance)])


	const applyApplicabilityChange = async (data) => {
		console.log("FO", data)
		if (Object.keys(data?.compliance)?.length > 0) {
			// If the data?.compliance has keys, trigger PUT
			try {
				const request = applicableTrigger(data?.compliance?.id);
				await request.api({
					data: {
						control: data?.compliance?.control,
						assigned_to: data?.compliance?.assigned_to,
						status: data?.applicable ? 'Not Applicable' : 'Applicable',
						date: data?.compliance?.date,
						type: complianceFrameworkTypes.Compliance,
						framework_id: data?.compliance?.framework_id,
					},
				});
				dispatch(controlVisited(data))
				setIsLoading(false)
				handleApplicableToggle()
				enqueueSnackbar('Successfully Updated !!!', {
					variant: 'success',
				});
			} catch (error) {
				enqueueSnackbar('Issue in updating control.', {
					variant: 'error',
				});
			}
		} else {
			// If data?.compliance is blank (e.g., {}), trigger POST
			const dateString = new Date().toISOString();
			const reqBody = {
				date: dateString,
				status: 'Not Applicable',
				assigned_to: userList[0]?.value || '',
				control: data?.id,
				framework_id: data?.framework,
				type: complianceFrameworkTypes.Compliance
			}
			const formData = jsonToFormData(reqBody)

			try {
				const request = applicableTriggerConditional();
				await request.api({
					data: formData,
				});
				dispatch(controlVisited(data))
				handleApplicableToggle()
				enqueueSnackbar('Successfully Updated !!!', {
					variant: 'success',
				});
			} catch (error) {
				enqueueSnackbar('Issue in updating control.', {
					variant: 'error',
				});
			}
		}
	}


	return (
		<Grid container spacing={3}>
			<Grid
				sx={{
					borderRadius: '16px',
					// backgroundColor: theme.palette.ascents.washLight,
					overflow: 'visible',
					'& .MuiList-padding': {
						padding: 0
					},
					'& .MuiListItemSecondaryAction-root': {
						position: 'static',
						top: 'auto',
						right: 'auto',
						transform: 'none'
					},
					'& p': {
						textTransform: 'uppercase',
						marginTop: '8px',
						fontSize: 26,
						fontWeight: 600,
						fontFamily: '"Open Sans", sans-serif',
						color: (theme) => theme.palette.ascents.base
					}
				}}
				item
				sm={3}
			>
				<CardWrapperByRatio
					aspectRatio={736 / 385}
					title='requirements'
					wrapperProps={{
						sx: {
							overflowY: 'scroll'
						}
					}}
				>
					{isLoading ? (
						<Box
							sx={{
								width: '100%',
								margin: '0 auto',
								height: '100%',
								position: 'relative',
								padding: '1rem 0',

								'& .loaderRoot': {
									height: '100%'
								},

								[theme.breakpoints.between(BREAKPOINTS.xl, BREAKPOINTS.xxl)]: {
									width: '40%'
								},
								[theme.breakpoints.between(BREAKPOINTS.xxl, BREAKPOINTS.xxxl)]: {
									width: '55%'
								},
								[theme.breakpoints.between(BREAKPOINTS.xxxl, BREAKPOINTS.xxxxl)]: {
									width: '55%'
								}
							}}
						>
							<Loader showSecurityFacts={false} />
						</Box>
					) : (
						Object.entries(requirementsAsPerCompliance).map(([key, requirements], index) => (
							<Collapsible
								open={subcontrol_ref_code ? !!requirements[subcontrol_ref_code] : index === 0}
								expandIconPostion='end'
								ascent='shadow'
								key={index}
								labelProps={{
									fontWeight: '600',
									fontSize: '14px !important',
									ascent: 'primary',

									sx: {
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between'
									}
								}}
								label={key}
								sx={{
									'& + .MuiCollapse-root': {
										// padding: "0.75rem",
										background: '#fafafa',
										borderRadius: '0px 0px 1rem 1rem'
									}
								}}
							>
								<Box padding='0.375rem 0.75rem 0.75rem 0.75rem '>
									{Object.entries(requirements).map(([reqKey, requirement]) => (
										<Button
											key={reqKey}
											ascent={requirement?.compliance?.status === `Not Applicable` ? 'wash' : 'white'}
											colorAscent='primary'
											margin='0.375rem 0 0 0'
											fontWeight='600'
											padding='1rem 1.5rem'
											title={`${reqKey} - ${requirement.desc}`}
											sx={{
												alignItems: 'flex-start',
												textAlign: 'left',
												textTransform: 'none',
												width: '100%',
												maxWidth: '100%',
												border: (theme) =>
													activeRequirement?.key === reqKey
														? `1px solid ${theme.palette.ascents.primary}`
														: `1px solid ${theme.palette.ascents.shadow}`,
												display: 'block',
												position: 'relative',
												fontSize: '0.75rem',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												'& .MuiButton-label': {
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													whiteSpace: 'nowrap'
												},

												'& .tag': {
													position: 'absolute',
													top: '50%',
													left: 0,
													width: '8px',
													height: '1.5rem',
													transform: 'translate(-50%, -50%)',
													borderRadius: '3rem'
												},

												'&:hover': {
													opacity: '1 !important'
												},

												opacity: activeRequirement?.key === reqKey ? 1 : 0.65
											}}
											borderRadius='1rem'
											onClick={handleOnActiveRequrirementChange({
												key: reqKey,
												...requirement
											})}
										>
											{`${reqKey}- ${requirement.name ? requirement.name : requirement.description}`}
											{requirement.count > 0 && <Box className='tag' component={'span'} bgcolor={ASCENTS['green']} />}
										</Button>
									))}
								</Box>
							</Collapsible>
						))
					)}
				</CardWrapperByRatio>
			</Grid>
			<Grid item sm={9}>
				<CardWrapperByRatio
					aspectRatio={705 / 1160}
					title='Requirement Details'
					wrapperProps={{
						sx: {
							overflowY: 'scroll'
						}
					}}
					RightComponent={
						<>
							{activeRequirement && 'applicable' in activeRequirement && <Box
								display='flex'
								alignItems='center'
								justifyContent={'space-evenly'}
								onClick={handleApplicableToggle}
								sx={{
									cursor: 'pointer'
								}}
							>
								<Text fontSize={14}>
									{activeRequirement.applicable ? <span style={{ color: '#19133f' }}>Applicable</span> : 'Not Applicable'}
								</Text>
								<Switch checked={activeRequirement.applicable} inputProps={{ 'aria-label': 'controlled' }} size='small' />
							</Box>}
						</>
					}
				>
					<>
						{activeRequirement?.key ? (
							<>
								<Text ascent='primary' fontSize={'1.25rem'}>
									{activeRequirement.key} {activeRequirement.name ? `${activeRequirement?.name}` : ''}
								</Text>
								<Text margin={'1rem 0 3rem 0'}>{activeRequirement.desc}</Text>

								<Text ascent='primary' fontSize={'1.25rem'}>
									Implementation Guidance
								</Text>
								<Text margin={'0.75rem 0 3rem 0'}>
									{activeRequirement.implementation_guidance?.split('\n\n')?.map((paragraph) => (
										<>
											<p>{paragraph}</p>
										</>
									))}
								</Text>

								<Text ascent='primary' textTransform='capitalize' fontSize={'1.25rem'}>
									recommended tools
								</Text>
								<Box mt={1.5} mb={3}>
									<RecommendedTools recommendedTools={activeRequirement.recommended_tool || []} />
								</Box>
								{/* <Text margin={'0.75rem 0 3rem 0'}>{activeRequirement.recommended_tools}</Text> */}

								<Text ascent='primary' textTransform='capitalize' fontSize={'1.25rem'}>
									category
								</Text>
								<AppChip
									label={activeRequirement.category}
									variant={'outlined'}
									margin={'0.75rem 0 3rem 0'}
									colorAscent='textDark'
									ascent='washDark'
								/>

								{activeRequirement.count > 0 && (
									<>
										<Text ascent='primary' fontSize={'1.25rem'}>
											Recent risks ({activeRequirement.count})
										</Text>
										<OpenSearchDataTable
											columns={allSignalsHeadCells}
											elasticQuery={elasticTableRequestBodyByRequirement[
												complianceType
											].getElasticRequestForRequirement(activeRequirement.key, timeRange)}
											detailsOnRowClick
										/>
									</>
								)}

								<ComplianceControlsTable activeRequirementKey={activeRequirement.key} frameworkKey={complianceType} />
							</>
						) : (
							<NothingIsSelected />
						)}
					</>
				</CardWrapperByRatio>
			</Grid>


			{/* modal for Compliance SOA confirmation */}
			<AppModal
				fullWidth
				maxWidth='md'
				title={'Confirmation'}
				onClose={handleApplicableToggle}
				open={applicableChecked}
				hideClose={true}
				showActions
				ActionComponent={
					<>
						<Box display='flex'>
							<Button ascent="mediumDarkGreen" onClick={() => applyApplicabilityChange(activeRequirement)} margin='0 0.5rem 0 0'>
								Apply Changes
							</Button>
						</Box>
					</>
				}
			>
				{activeRequirement && <Grid container>
					<Grid xs={12}>
						<Text ascent='primary' fontSize={'1.25rem'}>
							{activeRequirement?.key} {activeRequirement?.name ? `${activeRequirement?.name}` : ''}
						</Text>
						<Text margin={'1rem 0 3rem 0'}>{activeRequirement?.desc}</Text>
					</Grid>
					<Grid xs={12}>
						<Text ascent='primary' fontSize={'1.25rem'}>
							{`Are you sure you want to ${activeRequirement?.applicable ? `disable` : `enable`} this control?`}
						</Text>
						<Text margin={'1rem 0 3rem 0'}>{`This action will make it ${activeRequirement?.applicable ? `not applicable` : `applicable`} for your organization.`}</Text>
					</Grid>
				</Grid>}
			</AppModal>

		</Grid>
	)
}

export default ComplianceControls