import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    users: [],
    lastVisitedControl: {},
    activeTabIndex: -1
}

export const usersData = createSlice({
    name: 'users',
    initialState,
    reducers: {
        usersReducer: (state, action) => {
            state.users = action.payload
        },
        controlVisited: (state, action) => {
            state.lastVisitedControl = action.payload
        },
        tabChangeTrigger: (state, action) => {
            state.activeTabIndex = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { usersReducer, controlVisited, tabChangeTrigger } = usersData.actions

export const userDataReducer = usersData.reducer
